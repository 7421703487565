<div class="cl-progress-spacer">
  @if (isCompanyLoading()) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  }
</div>
<mat-form-field class="form-element">
  <mat-label>Company</mat-label>
  <mat-select [formControl]="companyControl" (selectionChange)="companyOnChange($event)" placeholder="Select Company">
    @for(item of companyList(); track item.companyId) {
    <mat-option [value]="item.companyId">
      {{item.companyName}}
    </mat-option>
    }
  </mat-select>
  <mat-error><app-print-error [control]="controlInfo().controlName"></app-print-error></mat-error>
</mat-form-field>
