import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { get, isEmpty } from 'lodash';
import { Subscription } from 'rxjs';
import { CREATE_REQUEST_URL } from 'src/app/RouteConstants';
import { MaterialModule } from 'src/app/material.module';
import { CompanyListComponent } from 'src/app/shared/includes/company-list/company-list.component';
import { EmployeeCardComponent } from 'src/app/shared/includes/employee-card/employee-card.component';
import { JobListComponent } from 'src/app/shared/includes/job-list/job-list.component';
import { PrintErrorComponent } from 'src/app/shared/includes/print-error/print-error.component';
import { ButtonComponent } from 'src/app/shared/library/button/button.component';
import { Button } from 'src/app/shared/library/button/button.model';
import { CompanyControlProperties } from 'src/app/shared/shared.model';
import { JobEmployeeView, JobSummary } from '../../shared/job-summary/job-summary.model';
import { CreateRequest } from '../create-request.model';
import { CreateRequestService } from '../create-request.service';

@Component({
  selector: 'app-new-request-detail',
  templateUrl: './new-request-detail.component.html',
  styleUrl: './new-request-detail.component.scss',
  imports: [
    ButtonComponent,
    CompanyListComponent,
    EmployeeCardComponent,
    JobListComponent,
    MaterialModule,
    PrintErrorComponent,
    ReactiveFormsModule,
  ]
})
export class NewRequestDetailComponent implements OnInit, OnDestroy {
  private createService = inject(CreateRequestService);
  private router = inject(Router);
  private dialog = inject(MatDialog);

  form: FormGroup = new FormGroup({
    companyId: new FormControl(0, Validators.required),
    jobInfo: new FormControl(null),
    approverInfo: new FormControl(null, Validators.required),
  });
  public levelOneJobEmployee = signal<JobEmployeeView[]>([]);

  public setSelectedCompany = (companyId: number) => {
    this.form.reset();
    this.form.setValue({
      companyId,
      jobInfo: {} as JobSummary,
      approverInfo: {} as JobEmployeeView,
    });
  }

  public companyControl = signal<CompanyControlProperties>({
    handler: this.setSelectedCompany,
    controlName: this.form.get('companyId'),
    triggerValidation: false,
  });

  public jobShowError = signal<boolean>(false);
  public jobDisabled = signal<boolean>(false);
  private subscribe$: Subscription = new Subscription();

  ngOnInit(): void {
    this.getSelectedData();
  }

  private getSelectedData() {
    this.subscribe$.add(this.createService.requestInformation$
      .subscribe((material: CreateRequest) => {
        if (!isEmpty(material?.companyId?.toString())) {
          this.companyControl.update(config => ({ ...config, defaultValue: material.companyId }));
          this.form.setValue({
            companyId: material.companyId as number,
            jobInfo: material.jobInfo as JobSummary,
            approverInfo: material.approverInfo as JobEmployeeView,
          });
        } else {
          this.form.setValue({
            companyId: 0,
            jobInfo: {} as JobSummary,
            approverInfo: {} as JobEmployeeView,
          });
        }
        this.setSelectedJob();
      }));
  }

  private setSelectedJob() {
    const selectedJob = this.form.get('jobInfo')!.value;
    this.levelOneJobEmployee.set(get(selectedJob, 'jobLevels[0].jobEmployeesView', []));
  }


  onJobChange(jobInfo: JobSummary) {
    this.form.get('jobInfo')!.setValue(jobInfo);
    this.setSelectedJob();
  }

  private continueCreateRequest = () => {
    if (this.form.valid) {
      this.createService.setRequestInformation(this.form.value);
      this.cancelButtonHandler();
      this.router.navigateByUrl(CREATE_REQUEST_URL);
    } else {
      this.form.markAllAsTouched();
    }
    this.jobShowError.set(this.form.invalid);
  }

  private cancelButtonHandler = () => {
    this.dialog.closeAll();
  }

  cancelButtonConfig: Button = {
    title: 'Cancel',
    action: this.cancelButtonHandler,
    analyticValue: 'Cancel Create Request',
    type: 'button',
    buttonColor: 'warn',
    icon: 'cancel'
  };

  continueButtonConfig: Button = {
    title: 'Continue',
    action: this.continueCreateRequest,
    showLoading: false,
    analyticValue: 'Continue to Create Request',
    buttonColor: 'primary',
    type: 'submit',
    icon: 'add'
  };

  ngOnDestroy() {
    this.subscribe$.unsubscribe();
  }
}
