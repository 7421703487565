import { Component, input } from '@angular/core';
import { MaterialModule } from 'src/app/material.module';
import { JobCardModel } from './job-card.model';

@Component({
    selector: 'app-job-card',
    imports: [
        MaterialModule,
    ],
    templateUrl: './job-card.component.html',
    styleUrl: './job-card.component.scss'
})
export class JobCardComponent {
  readonly jobInfo = input.required<JobCardModel>();
}
