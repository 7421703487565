<form [formGroup]="form" class="form-card">
  <div mat-dialog-title class="dialog-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-icon>post_add</mat-icon>
    New Request
  </div>
  <mat-dialog-content>
    <app-company-list
      [controlInfo]="companyControl()"
      class="form-element"
    ></app-company-list>
    <span class="nrd-hint-text">Must pick from list</span>
    <app-job-list
      [companyId]="form.get('companyId')!.value"
      [showError]="jobShowError()"
      [selectedValue]="form.get('jobInfo')!.value"
      [disabled]="jobDisabled()"
      class="form-element"
      (onSelection)="onJobChange($event)"
    ></app-job-list>
    <span class="nrd-hint-text">Inactive employees are disabled in the approver options.</span>
    <mat-form-field class="form-element">
      <mat-label>Approver</mat-label>
      <mat-select formControlName="approverInfo" placeholder="Choose...">
        <mat-select-trigger>
          <app-employee-card [employeeInfo]="form.get('approverInfo')!.value"></app-employee-card>
        </mat-select-trigger>
        @for (item of levelOneJobEmployee(); track item.id) {
        <mat-option [value]="item" [disabled]="item.employeeStatus !== 'Active'">
          <app-employee-card [employeeInfo]="item"></app-employee-card>
        </mat-option>
        }
      </mat-select>
      <mat-error><app-print-error [control]="form.get('approverInfo')"></app-print-error></mat-error>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <app-button [configuration]="[cancelButtonConfig, continueButtonConfig]"></app-button>
  </mat-dialog-actions>
</form>
