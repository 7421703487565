import { Component, effect, inject, input, OnChanges, output, signal, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSnackBar } from '@angular/material/snack-bar';
import { finalize, first } from 'rxjs';
import { AUTO_COMPLETE_MAX, SNACK_BAR_DISMISS_BUTTON, SNACK_BAR_ERROR_DURATION } from 'src/app/GlobalConstants';
import { GENERIC_API_ERROR } from 'src/app/MessageConstants';
import { JobListSummary, JobSummary } from 'src/app/client/shared/job-summary/job-summary.model';
import { JobSummaryService } from 'src/app/client/shared/job-summary/job-summary.service';
import { MaterialModule } from 'src/app/material.module';
import { JobCardComponent } from '../job-card/job-card.component';
import { PrintErrorComponent } from '../print-error/print-error.component';

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrl: './job-list.component.scss',
  imports: [
    JobCardComponent,
    MaterialModule,
    PrintErrorComponent,
    ReactiveFormsModule,
  ],
})
export class JobListComponent implements OnChanges {
  readonly companyId = input.required<number>();
  readonly showInactive = input<boolean>(false);
  readonly showAll = input<boolean>(false);
  readonly showError = input<boolean>(false);
  readonly onSelection = output<JobSummary>();
  readonly selectedValue = input<JobSummary>();
  readonly disabled = input<boolean>(false);

  private snackBar = inject(MatSnackBar);
  private jobService = inject(JobSummaryService);
  private jobList = signal<JobListSummary[]>([]);

  public isJobLoading = signal<boolean>(false);
  public filteredOptions = signal<JobListSummary[]>([]);

  public form = new FormGroup({
    control: new FormControl<JobSummary | undefined>(undefined, Validators.required),
  });

  constructor() {
    effect(() => {
      if (this.showError()) {
        this.form.markAllAsTouched();
      }
      if (this.disabled()) {
        this.form.disable();
      }
      if (this.selectedValue()) {
        const control = this.form.get('control');
        if (control && this.selectedValue()) {
          control.setValue(this.selectedValue());
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['companyId']) {
      this.form.reset();
      this.getJobList();
    }
  }

  onInput(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this.onInputChange(value);
  }

  displayFn = (selectedJobItem: JobSummary): string => {
    return selectedJobItem?.jobCode;
  }

  public onInputChange(value: string) {
    this.filteredOptions.set(this.jobFilteredResult(value));
  }

  jobControlChange(event: any) {
    const value = event.target.value;
    this.filteredOptions.set(this.jobFilteredResult(value));
  }

  onJobChange(event: MatAutocompleteSelectedEvent) {
    this.onSelection.emit(event.option.value);
  }

  errorStateMatcher = {
    isErrorState: () => this.showError(),
  };

  private getJobList() {
    if (this.companyId() && !this.isJobLoading()) {
      this.isJobLoading.set(true);
      this.jobService.getJobSummary(this.companyId()).pipe(
        first(),
        finalize(() => {
          this.isJobLoading.set(false);
          this.filteredOptionsValue();
        })
      ).subscribe({
        next: (jobData: JobSummary[]) => {
          this.jobList.set(jobData);
        },
        error: () => {
          this.snackBar.open(`Job List: ${GENERIC_API_ERROR}`, SNACK_BAR_DISMISS_BUTTON, {
            panelClass: 'error',
            duration: SNACK_BAR_ERROR_DURATION,
          });
        }
      });
    }
  }

  private filteredOptionsValue() {
    const jobListContent = this.jobList().filter(jobInfo => {
      const excludeAll = jobInfo.jobId !== 0;
      const excludeInactive = this.showInactive() ? true : jobInfo.jobStatus;
      return excludeAll && excludeInactive;
    });
    if (this.showAll()) {
      jobListContent.unshift({
        jobCode: 'All',
        jobId: 0,
        jobName: 'All Jobs',
      });
    }
    this.jobList.set(jobListContent);
    this.filteredOptions.set(this.jobList().slice(0, AUTO_COMPLETE_MAX));
  }

  private jobFilteredResult(value: string): JobListSummary[] {
    const filterValue = value.toString().toLowerCase();
    return this.jobList().filter(({
      jobCode = '',
      jobName = '',
    }) => jobCode.toLowerCase().includes(filterValue) || jobName.toLowerCase().includes(filterValue)).slice(0, AUTO_COMPLETE_MAX)
  }
}
