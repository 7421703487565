<div class="jl-progress-spacer">
  @if(isJobLoading()) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  }
</div>
<form [formGroup]="form">
  <mat-form-field class="form-element">
    <mat-label>Job</mat-label>
    <input type="text" matInput formControlName="control" (input)="onInput($event)" [matAutocomplete]="jobListFilter" />
    <mat-autocomplete autoActiveFirstOption #jobListFilter [displayWith]="displayFn"
      (optionSelected)="onJobChange($event)">
      @for (option of filteredOptions(); track option.jobId) {
      <mat-option [value]="option" class="jl-separator">
        <app-job-card [jobInfo]="option"></app-job-card>
      </mat-option>
      }
    </mat-autocomplete>
    <mat-error><app-print-error [control]="form.get('control')"></app-print-error></mat-error>
  </mat-form-field>
</form>
